*
{
    margin: 0;
    padding: 0;
}

/* html,body
{   background-color: rgb(239, 247, 214);
    overflow: hidden;
} */

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}

.loading-bar
{
    position:absolute;
    top:50%;
    width: 100%;
    height: 3px;
    background: white;
    transform: scaleX(0.3);
    transform-origin:top left;
    transition: transform 0.5s;
    will-change: transform;
}


.loading-text{
    position:absolute;
    top:55%;
    left: 45%;
    color:#3477eb;
    font-family: Arial, Helvetica, sans-serif;
    font-size: large;
    font-weight: bold;
}

.loading-bar.ended
{
    transform: scaleX(0);
    transform-origin: 100% 0;
    transition: transform 1.5s ease-in-out;
}